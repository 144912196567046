import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { injectIntl } from 'react-intl'
import { useLocation, useRouteMatch } from 'react-router-dom'
import Button from 'components/Button'
import Icon from 'components/Icon'
import propTypes from 'prop-types'
import { logout, selectCurrentUser, selectCurrentZone, selectIsInitialized, selectIsLoggedIn } from 'store/global'
import messages from './messages'
import styles from './Header.scss'
import { useMatomo } from '@datapunt/matomo-tracker-react'
import { exportSingleEntityFile } from 'services/verticalsApi'
import { downloadXLSX } from 'utils/DownloadXLSX'
import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'

function Header({ intl, currentZoneIndexes }) {
  const dispatch = useDispatch()
  const location = useLocation()
  const { trackEvent } = useMatomo()

  const match = useRouteMatch('/vertical/:vertical/:entityId')

  const [loading, setLoading] = useState(false)
  const isInitialized = useSelector(selectIsInitialized)
  const currentZone = useSelector(selectCurrentZone)
  const isLoggedIn = useSelector(selectIsLoggedIn)

  const currentUser = useSelector(selectCurrentUser)
  const { email = '', fullName = '' } = currentUser || {}

  const { name = '' } = currentZone || {}
  const currentZoneName = name

  const onXLSXExport = async () => {
    setLoading(true)
    try {
      const entityId = match.params.entityId
      const csvTypes = {
        baseCsvType: `single-entity-${match.params.vertical}`,
        activityCsvType: `activity-${match.params.vertical}`,
      }
      const verticalType = match.params.vertical

      const Base64EntityFile = await exportSingleEntityFile(entityId, currentZoneIndexes, csvTypes, verticalType)
      downloadXLSX(Base64EntityFile, 'single-entity-' + entityId + '.xlsx')
      trackEvent({ category: 'Export', action: 'User requested for single entity XLSX' })
    } catch (error) {
      console.error('Export failed', error)
    } finally {
      setLoading(false)
    }
  }
  return (
    <>
      <Backdrop open={loading} style={{ zIndex: 1300, color: '#fff' }}>
        <CircularProgress color='inherit' />
      </Backdrop>
      <header className={styles.header}>
        <Button
          to={isLoggedIn ? '/dashboard' : '/login'}
          color='link'
          className={styles.logoLink}
          title={intl.formatMessage(messages.logo)}
        >
          <Icon name='logo' size='xl' className={styles.logo} />
        </Button>
        <div className={styles.linksWrapper}>
          <ul className={styles.linksList}>
            <li className={styles.linkItem}>
              <Button color='link' to={email ? '/profile' : undefined} title={email} className={styles.profile}>
                <span className={styles.username}>{fullName}</span>
              </Button>
              {isLoggedIn && (
                <Button color='link' onClick={() => dispatch(logout())}>
                  <Icon name='logout' size='regular' className={styles.logout} />
                </Button>
              )}
            </li>
            <li className={styles.linkItem}>
              <Button color='link'>
                <Icon name='bell' size='regular' className={styles.bellIcon} />
              </Button>
            </li>
            <li className={styles.linkItem}>
              {currentZoneName && (
                <div className={styles.currentZone}>
                  <span className={styles.zoneName}>{currentZoneName}</span>
                </div>
              )}
            </li>
            {location.pathname.includes('/vertical') && (
              <li>
                <Button color='light' outline className={styles.export} disabled={false} onClick={onXLSXExport}>
                  <Icon name='exportFile' size='regular' />
                  EXPORT
                </Button>
              </li>
            )}
          </ul>
        </div>
      </header>
    </>
  )
}

Header.propTypes = {
  intl: propTypes.object.isRequired,
}

export default injectIntl(Header)
